import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SponsorsStateService {

    public isSponsorFooterDisplayed = new BehaviorSubject<boolean>(false);

    public showSponsorFooter() {
        this.isSponsorFooterDisplayed.next(true);
    }

    public hideSponsorFooter() {
        this.isSponsorFooterDisplayed.next(false);
    }
}